// Colors
$black:     #161E48;
$blue:      #162150;
$darkBlue:  #0C1025;
$grey:      #F5F5FF;
$white:     #FFFFFF;
$red:       #fb4202;
$yellow:    #FCEEA7;
$darkYellow: #FEDA03;
$hm-blue:   #89eec1;

// Font
$bodyFontSize: 16px;
$font-size-base: 1rem;
$line-height-base: 1.2;
$font-family-base: 'work sans', sans-serif;

// Selection
$selectionBg: $yellow;
$selectionColor: $black;

// Grid / Container
$containerWidth: 920px;
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: $containerWidth,
    xxl: 1320px
);
$grid-gutter-width: 30px;

// Spacing
$spacers: (
    0: 0,
    1: 15px,
    2: 30px,
    3: 60px,
    4: 90px,
    5: 120px,
    6: 240px,
);

// hm Overwrite Bootstrap vars
$body-bg: $white;
$body-color: $black;
