header {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 40px;

    .logo {
        transition: transform .4s cubic-bezier(.22, .46, .18, 1.13);

        img {
            width: 172px;
            height: 43px;
        }

        &:hover {
            transform: scale(1.03);
        }
    }

    .naviContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .raribleContainer {
        margin-left: 40px;
        transition: transform .4s cubic-bezier(.22, .46, .18, 1.13);

        a {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        span {
            color: $darkYellow;
            text-transform: uppercase;
        }

        img {
            width: 73px;
            height: 73px;
            margin-left: 30px;
        }

        &:hover {
            transform: scale(1.03);
        }
    }

    .navi {
        display: flex;
        flex-direction: row;
        padding: 0;
        margin: 0;

        li {
            @include bold;
            list-style: none;
            margin: 0 10px;
            text-transform: uppercase;

            a {
                position: relative;
                display: inline-block;
                color: $yellow;

                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -10px;
                    height: 4px;
                    background-color: $yellow;
                    transform: scale(0,1);
                    transition: transform .3s ease;
                    transform-origin: right center;
                }
            }

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            &:hover, &.active {
                a::after {
                    transform-origin: left center;
                    transform: scale(1,1);
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    header {
        position: relative;
        flex-direction: column;
        background-color: $darkBlue;
        padding: 25px;

        .naviContainer {
            justify-content: center;
            margin-top: 5px;
        }

        .raribleContainer {
            display: none;

            img {
                width: 35px;
                height: 35px;
                margin-left: 0;
            }

            span {
                display: none;
            }
        }
    }

    .logo {
        margin-bottom: 10px;
    }
}
