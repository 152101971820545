#onRarible {
    background-color: $darkYellow;
    text-align: center;

    a {
        @include bold;
        position: relative;
        display: inline-block;
        font-size: 1.25em;
        text-transform: uppercase;
        padding: 26px 36px 23px;

        &::after {
            @include absolute;
            content: '';
            border: solid 6px $black;
            transition: transform .3s cubic-bezier(.29, .72, 0, 1.37);
        }

        &:hover, &:active {
            &:after {
                transform: scale(1.03);
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    #onRarible {
        padding: 20px;
    }
}
