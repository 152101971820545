#about {
    .teaserHeader {
        display: flex;
        justify-content: space-between;
        align-items: start;

        h3 {
            margin-bottom: 0;
        }

        .btn {
            margin-top: 0;
        }
    }

    .teaserContent {
        columns: 2;
        column-gap: 30px;
    }
}

@include media-breakpoint-down(sm) {
    #about {
        .teaserHeader {
            flex-direction: column;

            h3 {
                margin-bottom: 15px;
                width: 100%;
            }
        }

        .teaserContent {
            columns: 1;
            column-gap: 0;
        }
    }
}
