#howItWorks {
    position: relative;
    z-index: 0;

    .howItWorksCover {
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-right: 70px;

        svg {
            position: absolute;
            top: -45px;
            left: -38px;
            z-index: 1;
        }

        span {
            @include extraBold;
            position: relative;
            z-index: 1;
            color: $white;
            font-size: 6.25em;
            text-align: right;
            line-height: .4;
            margin-top: -14px;
            margin-right: -5px;
        }

        .imgContainer {
            position: relative;
            max-width: 360px;

            .morph, .drawing, .faces {
                width: 100%;
            }

            .drawing {
                @include absolute;
            }

            .faces {
                @include absoluteCenter;
                max-width: 66.6666%;
            }
        }
    }

    .howItWorksContainer {
        padding-left: 32px;

        ul {
            list-style: none;
            counter-reset: item;
            padding: 0;

            li {
                @include regular;
                position: relative;
                display: inline-block;
                counter-increment: item;
                margin-bottom: 30px;
                margin-top: 0;
                font-size: 1.25em;
                text-transform: uppercase;
                letter-spacing: -.01em;

                u {
                    &:hover {
                        text-decoration: line-through;
                    }
                }

                &::before {
                    @include bold;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: -9px;
                    left: -70px;
                    content: counter(item);
                    width: 43px;
                    height: 43px;
                    border: solid 2px white;
                    border-radius: 50%;
                    background-color: $yellow;
                    transition: background-color .3s ease;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &:hover {
                    &::before {
                        background-color: $white;
                    }
                }
            }
        }

        .btn {
            display: inline-block;
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: calc(50% - 3px);
        background-color: $white;
        width: 3px;
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 60px;
        bottom: -240px;
        z-index: -1;
        background-color: $yellow;
    }
}

@include media-breakpoint-down(sm) {
    #howItWorks {
        .howItWorksCover {
            padding-right: 0;

            .imgContainer {
                max-width: 100%;
            }

            svg {
                top: -45px;
                left: 1px;
            }

            span {
                font-size: 3.8em;
            }
        }

        .howItWorksContainer {
            padding-left: 0;

            ul {
                padding-left: 46px;

                li {
                    .countdown {
                        .ms {
                            display: none;
                        }
                    }

                    &::before {
                        width: 35px;
                        height: 35px;
                        left: -48px;
                        top: -6px;
                    }
                }
            }
        }

        &::before {
            content: none;
        }
    }
}
