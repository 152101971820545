#bigHeader {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 540px;
    height: 66vh;
    background-color: $blue;

    .bigHeaderbg {
        @include absolute;
        @include cover;

        &.blured {
            filter: blur(30px);
            opacity: 0;
            transition: opacity 1.5s ease;
        }
    }

    .bigheaderContent {
        padding: 120px 0 60px;

        .headline {
            margin-bottom: 120px;
            padding-left: 90px;
        }

        h1 {
            color: $white;

            .char,
            .whitespace {
                opacity: 0;
                transition: opacity .15s ease;

                &.vis {
                    opacity: 1;
                }
            }
        }

        .countdownContainer {
            color: $yellow;
            text-align: center;

            .countdown {
                .ms {
                    display: inline-block;
                    min-width: 80px;
                    text-align: right;
                }
            }
        }

        h2 {
            color: $yellow;
            text-align: center;

            span {
                display: inline-block;
                margin: 0;
            }
        }
    }

    &.loaded {
        .bigHeaderbg.blured {
            opacity: 1;
        }
    }
}

@include media-breakpoint-down(sm) {
    #bigHeader {
        min-height: auto;
        height: auto;

        .bigheaderContent {
            padding: 30px 0 60px;

            h1 {
                font-size: 2.5em;
                margin-bottom: 15px;
                display: inline-block;
                color: $white;
            }

            h2 {
                text-align: left;
            }

            .headline {
                padding-left: 15px;
                margin-bottom: 15px;
            }

            .countdownContainer {
                text-align: left;
            }
        }
    }
}
