#jingles {
    .teaserHeader {
        .h3 {
            text-decoration: none;

            .marker {
                background-color: $darkBlue;
                color: $white;
                padding: 0 15px;
                margin: 0 7px;
            }
        }
    }
}

#jingleContainer {
    img, audio {
        width: 100%;
    }
}

.jingle {
    position: relative;
    z-index: 0;
    flex: 0 0 100%;
    max-width: 100%;

    .h4 {
        @include bold;
        hyphens: auto;
    }

    .jingleImg {
        position: relative;
        overflow: hidden;
        z-index: 0;

        span {
            display: inline-block;
            position: absolute;
            right: 13px;
            bottom: 7px;
            margin: 0;
            text-decoration: none;
            z-index: 2;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            width: 230px;
            height: 230px;
            transform: translate(50%, 50%);
            background: radial-gradient(circle, #FFFFFF 0%, rgba(255, 255, 255, 0) 60%);
            opacity: .6;
            z-index: 1;
            transition: opacity .2s ease;
            pointer-events: none;
        }
    }

    .player {
        display: none;
    }

    .jinglePlayer {
        display: flex;
        margin-top: 15px;

        .playBtn {
            position: relative;
            flex: 0 0 36px;
            cursor: pointer;
            border: solid 1px $darkBlue;
            border-radius: 50%;
            width: 36px;
            height: 36px;
            margin-right: 15px;
            opacity: .5;
            pointer-events: none;

            &::after {
                @include absoluteCenter;
                left: calc(50% + 1px);
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 7.5px 0 7.5px 13px;
                border-color: transparent transparent transparent $darkBlue;
            }

            &::before {
                @include absolute;
                z-index: -1;
                border-radius: 50%;
                content: '';
                background-color: $yellow;
                transition: all .45s cubic-bezier(.22, .57, .28, 1.43);
                opacity: 0;
                transform: scale(.7);
            }

            .pause {
                display: none;

                &::after, &::before {
                    @include absoluteCenter;
                    left: calc(50% + 4px);
                    content: '';
                    width: 4px;
                    height: 13px;
                    background-color: $darkBlue;
                }

                &::after {
                    left: calc(50% - 4px);
                }
            }

            &:hover {
                &::before {
                    opacity: 1;
                    transform: scale(1.5);
                }
            }
        }

        .jingleContent {
            .time {
                display: block;
                margin-top: 2px;
                font-size: .8em;
            }
        }
    }

    &:hover {
        .jingleImg::after {
            opacity: 0;
        }
    }

    &.playing {
        .jinglePlayer .playBtn {

            &::before {
                opacity: 1;
                transform: scale(1);
            }

            &::after {
                display: none;
            }

            .pause {
                display: block;
            }

            &:hover {
                &::before {
                    transform: scale(1.3);
                }
            }
        }
    }

    &.ready {
        .jinglePlayer .playBtn {
            opacity: 1;
            pointer-events: all;
        }
    }
}

@include media-breakpoint-down(sm) {
    #jingleContainer {
        padding: 0 7px 0 8px;

        .col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6,
        .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11,
        .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2,
        .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7,
        .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12,
        .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3,
        .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6,
        .col-5, .col-4, .col-3, .col-2, .col-1 {
            padding-right: 7px;
            padding-left: 8px;
        }
    }

    #jingles {
        .teaserHeader {
            .h3 {
                font-size: 1.45rem;
            }

            br {
                display: none;
            }
        }
    }

    .jingle {
        .h4 {
            font-size: .85em;
            display: inline-block;
            margin: 0;
        }

        .jinglePlayer {
            margin-top: 10px;

            .time {
                font-size: .7em;
                margin: 0;
            }

            .playBtn {
                flex: 0 0 30px;
                width: 30px;
                height: 30px;
                margin-right: 10px;
            }
        }
    }
}
