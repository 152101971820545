@charset "UTF-8";
@import url("https://use.typekit.net/kck8fmm.css");
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(22, 30, 72, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "work sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
  color: #161E48;
  text-align: left;
  background-color: #FFFFFF;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #162150;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #060814;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 920px;
  }
}
.container-xl, .container-lg, .container-md, .container-sm, .container {
  max-width: 1320px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(22, 30, 72, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #162150 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0b1128 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #FCEEA7 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #fae476 !important;
}

.bg-danger {
  background-color: #fb4202 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #c83502 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #FFFFFF !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #162150 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #FCEEA7 !important;
}

.border-danger {
  border-color: #fb4202 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #FFFFFF !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(22, 30, 72, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(22, 30, 72, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(22, 30, 72, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 15px !important;
}

.mt-1,
.my-1 {
  margin-top: 15px !important;
}

.mr-1,
.mx-1 {
  margin-right: 15px !important;
}

.mb-1,
.my-1 {
  margin-bottom: 15px !important;
}

.ml-1,
.mx-1 {
  margin-left: 15px !important;
}

.m-2 {
  margin: 30px !important;
}

.mt-2,
.my-2 {
  margin-top: 30px !important;
}

.mr-2,
.mx-2 {
  margin-right: 30px !important;
}

.mb-2,
.my-2 {
  margin-bottom: 30px !important;
}

.ml-2,
.mx-2 {
  margin-left: 30px !important;
}

.m-3 {
  margin: 60px !important;
}

.mt-3,
.my-3 {
  margin-top: 60px !important;
}

.mr-3,
.mx-3 {
  margin-right: 60px !important;
}

.mb-3,
.my-3 {
  margin-bottom: 60px !important;
}

.ml-3,
.mx-3 {
  margin-left: 60px !important;
}

.m-4 {
  margin: 90px !important;
}

.mt-4,
.my-4 {
  margin-top: 90px !important;
}

.mr-4,
.mx-4 {
  margin-right: 90px !important;
}

.mb-4,
.my-4 {
  margin-bottom: 90px !important;
}

.ml-4,
.mx-4 {
  margin-left: 90px !important;
}

.m-5 {
  margin: 120px !important;
}

.mt-5,
.my-5 {
  margin-top: 120px !important;
}

.mr-5,
.mx-5 {
  margin-right: 120px !important;
}

.mb-5,
.my-5 {
  margin-bottom: 120px !important;
}

.ml-5,
.mx-5 {
  margin-left: 120px !important;
}

.m-6 {
  margin: 240px !important;
}

.mt-6,
.my-6 {
  margin-top: 240px !important;
}

.mr-6,
.mx-6 {
  margin-right: 240px !important;
}

.mb-6,
.my-6 {
  margin-bottom: 240px !important;
}

.ml-6,
.mx-6 {
  margin-left: 240px !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 15px !important;
}

.pt-1,
.py-1 {
  padding-top: 15px !important;
}

.pr-1,
.px-1 {
  padding-right: 15px !important;
}

.pb-1,
.py-1 {
  padding-bottom: 15px !important;
}

.pl-1,
.px-1 {
  padding-left: 15px !important;
}

.p-2 {
  padding: 30px !important;
}

.pt-2,
.py-2 {
  padding-top: 30px !important;
}

.pr-2,
.px-2 {
  padding-right: 30px !important;
}

.pb-2,
.py-2 {
  padding-bottom: 30px !important;
}

.pl-2,
.px-2 {
  padding-left: 30px !important;
}

.p-3 {
  padding: 60px !important;
}

.pt-3,
.py-3 {
  padding-top: 60px !important;
}

.pr-3,
.px-3 {
  padding-right: 60px !important;
}

.pb-3,
.py-3 {
  padding-bottom: 60px !important;
}

.pl-3,
.px-3 {
  padding-left: 60px !important;
}

.p-4 {
  padding: 90px !important;
}

.pt-4,
.py-4 {
  padding-top: 90px !important;
}

.pr-4,
.px-4 {
  padding-right: 90px !important;
}

.pb-4,
.py-4 {
  padding-bottom: 90px !important;
}

.pl-4,
.px-4 {
  padding-left: 90px !important;
}

.p-5 {
  padding: 120px !important;
}

.pt-5,
.py-5 {
  padding-top: 120px !important;
}

.pr-5,
.px-5 {
  padding-right: 120px !important;
}

.pb-5,
.py-5 {
  padding-bottom: 120px !important;
}

.pl-5,
.px-5 {
  padding-left: 120px !important;
}

.p-6 {
  padding: 240px !important;
}

.pt-6,
.py-6 {
  padding-top: 240px !important;
}

.pr-6,
.px-6 {
  padding-right: 240px !important;
}

.pb-6,
.py-6 {
  padding-bottom: 240px !important;
}

.pl-6,
.px-6 {
  padding-left: 240px !important;
}

.m-n1 {
  margin: -15px !important;
}

.mt-n1,
.my-n1 {
  margin-top: -15px !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -15px !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -15px !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -15px !important;
}

.m-n2 {
  margin: -30px !important;
}

.mt-n2,
.my-n2 {
  margin-top: -30px !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -30px !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -30px !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -30px !important;
}

.m-n3 {
  margin: -60px !important;
}

.mt-n3,
.my-n3 {
  margin-top: -60px !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -60px !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -60px !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -60px !important;
}

.m-n4 {
  margin: -90px !important;
}

.mt-n4,
.my-n4 {
  margin-top: -90px !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -90px !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -90px !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -90px !important;
}

.m-n5 {
  margin: -120px !important;
}

.mt-n5,
.my-n5 {
  margin-top: -120px !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -120px !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -120px !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -120px !important;
}

.m-n6 {
  margin: -240px !important;
}

.mt-n6,
.my-n6 {
  margin-top: -240px !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -240px !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -240px !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -240px !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 15px !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 15px !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 15px !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 15px !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 15px !important;
  }

  .m-sm-2 {
    margin: 30px !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 30px !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 30px !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 30px !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 30px !important;
  }

  .m-sm-3 {
    margin: 60px !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 60px !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 60px !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 60px !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 60px !important;
  }

  .m-sm-4 {
    margin: 90px !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 90px !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 90px !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 90px !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 90px !important;
  }

  .m-sm-5 {
    margin: 120px !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 120px !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 120px !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 120px !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 120px !important;
  }

  .m-sm-6 {
    margin: 240px !important;
  }

  .mt-sm-6,
.my-sm-6 {
    margin-top: 240px !important;
  }

  .mr-sm-6,
.mx-sm-6 {
    margin-right: 240px !important;
  }

  .mb-sm-6,
.my-sm-6 {
    margin-bottom: 240px !important;
  }

  .ml-sm-6,
.mx-sm-6 {
    margin-left: 240px !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 15px !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 15px !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 15px !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 15px !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 15px !important;
  }

  .p-sm-2 {
    padding: 30px !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 30px !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 30px !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 30px !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 30px !important;
  }

  .p-sm-3 {
    padding: 60px !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 60px !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 60px !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 60px !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 60px !important;
  }

  .p-sm-4 {
    padding: 90px !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 90px !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 90px !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 90px !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 90px !important;
  }

  .p-sm-5 {
    padding: 120px !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 120px !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 120px !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 120px !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 120px !important;
  }

  .p-sm-6 {
    padding: 240px !important;
  }

  .pt-sm-6,
.py-sm-6 {
    padding-top: 240px !important;
  }

  .pr-sm-6,
.px-sm-6 {
    padding-right: 240px !important;
  }

  .pb-sm-6,
.py-sm-6 {
    padding-bottom: 240px !important;
  }

  .pl-sm-6,
.px-sm-6 {
    padding-left: 240px !important;
  }

  .m-sm-n1 {
    margin: -15px !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -15px !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -15px !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -15px !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -15px !important;
  }

  .m-sm-n2 {
    margin: -30px !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -30px !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -30px !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -30px !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -30px !important;
  }

  .m-sm-n3 {
    margin: -60px !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -60px !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -60px !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -60px !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -60px !important;
  }

  .m-sm-n4 {
    margin: -90px !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -90px !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -90px !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -90px !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -90px !important;
  }

  .m-sm-n5 {
    margin: -120px !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -120px !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -120px !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -120px !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -120px !important;
  }

  .m-sm-n6 {
    margin: -240px !important;
  }

  .mt-sm-n6,
.my-sm-n6 {
    margin-top: -240px !important;
  }

  .mr-sm-n6,
.mx-sm-n6 {
    margin-right: -240px !important;
  }

  .mb-sm-n6,
.my-sm-n6 {
    margin-bottom: -240px !important;
  }

  .ml-sm-n6,
.mx-sm-n6 {
    margin-left: -240px !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 15px !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 15px !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 15px !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 15px !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 15px !important;
  }

  .m-md-2 {
    margin: 30px !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 30px !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 30px !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 30px !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 30px !important;
  }

  .m-md-3 {
    margin: 60px !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 60px !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 60px !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 60px !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 60px !important;
  }

  .m-md-4 {
    margin: 90px !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 90px !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 90px !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 90px !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 90px !important;
  }

  .m-md-5 {
    margin: 120px !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 120px !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 120px !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 120px !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 120px !important;
  }

  .m-md-6 {
    margin: 240px !important;
  }

  .mt-md-6,
.my-md-6 {
    margin-top: 240px !important;
  }

  .mr-md-6,
.mx-md-6 {
    margin-right: 240px !important;
  }

  .mb-md-6,
.my-md-6 {
    margin-bottom: 240px !important;
  }

  .ml-md-6,
.mx-md-6 {
    margin-left: 240px !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 15px !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 15px !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 15px !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 15px !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 15px !important;
  }

  .p-md-2 {
    padding: 30px !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 30px !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 30px !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 30px !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 30px !important;
  }

  .p-md-3 {
    padding: 60px !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 60px !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 60px !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 60px !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 60px !important;
  }

  .p-md-4 {
    padding: 90px !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 90px !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 90px !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 90px !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 90px !important;
  }

  .p-md-5 {
    padding: 120px !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 120px !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 120px !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 120px !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 120px !important;
  }

  .p-md-6 {
    padding: 240px !important;
  }

  .pt-md-6,
.py-md-6 {
    padding-top: 240px !important;
  }

  .pr-md-6,
.px-md-6 {
    padding-right: 240px !important;
  }

  .pb-md-6,
.py-md-6 {
    padding-bottom: 240px !important;
  }

  .pl-md-6,
.px-md-6 {
    padding-left: 240px !important;
  }

  .m-md-n1 {
    margin: -15px !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -15px !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -15px !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -15px !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -15px !important;
  }

  .m-md-n2 {
    margin: -30px !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -30px !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -30px !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -30px !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -30px !important;
  }

  .m-md-n3 {
    margin: -60px !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -60px !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -60px !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -60px !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -60px !important;
  }

  .m-md-n4 {
    margin: -90px !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -90px !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -90px !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -90px !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -90px !important;
  }

  .m-md-n5 {
    margin: -120px !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -120px !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -120px !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -120px !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -120px !important;
  }

  .m-md-n6 {
    margin: -240px !important;
  }

  .mt-md-n6,
.my-md-n6 {
    margin-top: -240px !important;
  }

  .mr-md-n6,
.mx-md-n6 {
    margin-right: -240px !important;
  }

  .mb-md-n6,
.my-md-n6 {
    margin-bottom: -240px !important;
  }

  .ml-md-n6,
.mx-md-n6 {
    margin-left: -240px !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 15px !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 15px !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 15px !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 15px !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 15px !important;
  }

  .m-lg-2 {
    margin: 30px !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 30px !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 30px !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 30px !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 30px !important;
  }

  .m-lg-3 {
    margin: 60px !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 60px !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 60px !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 60px !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 60px !important;
  }

  .m-lg-4 {
    margin: 90px !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 90px !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 90px !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 90px !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 90px !important;
  }

  .m-lg-5 {
    margin: 120px !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 120px !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 120px !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 120px !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 120px !important;
  }

  .m-lg-6 {
    margin: 240px !important;
  }

  .mt-lg-6,
.my-lg-6 {
    margin-top: 240px !important;
  }

  .mr-lg-6,
.mx-lg-6 {
    margin-right: 240px !important;
  }

  .mb-lg-6,
.my-lg-6 {
    margin-bottom: 240px !important;
  }

  .ml-lg-6,
.mx-lg-6 {
    margin-left: 240px !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 15px !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 15px !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 15px !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 15px !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 15px !important;
  }

  .p-lg-2 {
    padding: 30px !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 30px !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 30px !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 30px !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 30px !important;
  }

  .p-lg-3 {
    padding: 60px !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 60px !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 60px !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 60px !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 60px !important;
  }

  .p-lg-4 {
    padding: 90px !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 90px !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 90px !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 90px !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 90px !important;
  }

  .p-lg-5 {
    padding: 120px !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 120px !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 120px !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 120px !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 120px !important;
  }

  .p-lg-6 {
    padding: 240px !important;
  }

  .pt-lg-6,
.py-lg-6 {
    padding-top: 240px !important;
  }

  .pr-lg-6,
.px-lg-6 {
    padding-right: 240px !important;
  }

  .pb-lg-6,
.py-lg-6 {
    padding-bottom: 240px !important;
  }

  .pl-lg-6,
.px-lg-6 {
    padding-left: 240px !important;
  }

  .m-lg-n1 {
    margin: -15px !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -15px !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -15px !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -15px !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -15px !important;
  }

  .m-lg-n2 {
    margin: -30px !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -30px !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -30px !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -30px !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -30px !important;
  }

  .m-lg-n3 {
    margin: -60px !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -60px !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -60px !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -60px !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -60px !important;
  }

  .m-lg-n4 {
    margin: -90px !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -90px !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -90px !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -90px !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -90px !important;
  }

  .m-lg-n5 {
    margin: -120px !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -120px !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -120px !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -120px !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -120px !important;
  }

  .m-lg-n6 {
    margin: -240px !important;
  }

  .mt-lg-n6,
.my-lg-n6 {
    margin-top: -240px !important;
  }

  .mr-lg-n6,
.mx-lg-n6 {
    margin-right: -240px !important;
  }

  .mb-lg-n6,
.my-lg-n6 {
    margin-bottom: -240px !important;
  }

  .ml-lg-n6,
.mx-lg-n6 {
    margin-left: -240px !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 15px !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 15px !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 15px !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 15px !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 15px !important;
  }

  .m-xl-2 {
    margin: 30px !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 30px !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 30px !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 30px !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 30px !important;
  }

  .m-xl-3 {
    margin: 60px !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 60px !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 60px !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 60px !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 60px !important;
  }

  .m-xl-4 {
    margin: 90px !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 90px !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 90px !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 90px !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 90px !important;
  }

  .m-xl-5 {
    margin: 120px !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 120px !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 120px !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 120px !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 120px !important;
  }

  .m-xl-6 {
    margin: 240px !important;
  }

  .mt-xl-6,
.my-xl-6 {
    margin-top: 240px !important;
  }

  .mr-xl-6,
.mx-xl-6 {
    margin-right: 240px !important;
  }

  .mb-xl-6,
.my-xl-6 {
    margin-bottom: 240px !important;
  }

  .ml-xl-6,
.mx-xl-6 {
    margin-left: 240px !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 15px !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 15px !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 15px !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 15px !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 15px !important;
  }

  .p-xl-2 {
    padding: 30px !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 30px !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 30px !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 30px !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 30px !important;
  }

  .p-xl-3 {
    padding: 60px !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 60px !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 60px !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 60px !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 60px !important;
  }

  .p-xl-4 {
    padding: 90px !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 90px !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 90px !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 90px !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 90px !important;
  }

  .p-xl-5 {
    padding: 120px !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 120px !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 120px !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 120px !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 120px !important;
  }

  .p-xl-6 {
    padding: 240px !important;
  }

  .pt-xl-6,
.py-xl-6 {
    padding-top: 240px !important;
  }

  .pr-xl-6,
.px-xl-6 {
    padding-right: 240px !important;
  }

  .pb-xl-6,
.py-xl-6 {
    padding-bottom: 240px !important;
  }

  .pl-xl-6,
.px-xl-6 {
    padding-left: 240px !important;
  }

  .m-xl-n1 {
    margin: -15px !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -15px !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -15px !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -15px !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -15px !important;
  }

  .m-xl-n2 {
    margin: -30px !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -30px !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -30px !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -30px !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -30px !important;
  }

  .m-xl-n3 {
    margin: -60px !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -60px !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -60px !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -60px !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -60px !important;
  }

  .m-xl-n4 {
    margin: -90px !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -90px !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -90px !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -90px !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -90px !important;
  }

  .m-xl-n5 {
    margin: -120px !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -120px !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -120px !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -120px !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -120px !important;
  }

  .m-xl-n6 {
    margin: -240px !important;
  }

  .mt-xl-n6,
.my-xl-n6 {
    margin-top: -240px !important;
  }

  .mr-xl-n6,
.mx-xl-n6 {
    margin-right: -240px !important;
  }

  .mb-xl-n6,
.my-xl-n6 {
    margin-bottom: -240px !important;
  }

  .ml-xl-n6,
.mx-xl-n6 {
    margin-left: -240px !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #FFFFFF !important;
}

.text-primary {
  color: #162150 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #060814 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #FCEEA7 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #f9e05d !important;
}

.text-danger {
  color: #fb4202 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #af2e01 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #161E48 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(22, 30, 72, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.sideFloat {
  width: calc((((100vw - 920px)/ 2) + 100%) + 5px);
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
  margin: 20px 0;
}
.video-container iframe, .video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.embed-container {
  max-width: 500px;
  margin: 15px auto;
}
.embed-container iframe, .embed-container twitter-widget {
  margin-left: auto !important;
  margin-right: auto !important;
}

.clear:after {
  content: "";
  display: table;
  clear: both;
}

.fadeIn, .slideIn,
.fadeInRow > div,
.fadeInRow > li,
.fadeInRow > p,
.fadeInRow > span,
.fadeInRow > h3,
.fadeInRow > a {
  opacity: 0;
  transition: all 0s ease-out;
  transform: translate(0, 10px);
}
.fadeIn.slideIn, .slideIn.slideIn,
.fadeInRow > div.slideIn,
.fadeInRow > li.slideIn,
.fadeInRow > p.slideIn,
.fadeInRow > span.slideIn,
.fadeInRow > h3.slideIn,
.fadeInRow > a.slideIn {
  transform: translate(-20px, 0);
}
.fadeIn.vis, .slideIn.vis,
.fadeInRow > div.vis,
.fadeInRow > li.vis,
.fadeInRow > p.vis,
.fadeInRow > span.vis,
.fadeInRow > h3.vis,
.fadeInRow > a.vis {
  transition: all 0.45s cubic-bezier(0.49, 0.59, 0.21, 1.07) 0.15s;
  transform: translate(0, 0);
  opacity: 1;
}

.mobile {
  display: none;
}
@media (max-width: 767.98px) {
  .mobile {
    display: block;
  }
}

.tablet {
  display: none;
}
@media (max-width: 991.98px) {
  .tablet {
    display: block;
  }
}

@media (max-width: 1199.98px) {
  .desktop {
    display: none;
  }
}

body {
  font-size: 16px;
}

h1, h2, h3, h4, h5, h6, p, a, span, ul, ol {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.2;
  margin-top: 15px;
  margin-bottom: 15px;
}
h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child, p:first-child, a:first-child, span:first-child, ul:first-child, ol:first-child {
  margin-top: 0;
}
h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child, p:last-child, a:last-child, span:last-child, ul:last-child, ol:last-child {
  margin-bottom: 0;
}

h1, .h1, h2, .h2, h3, h4 {
  font-weight: 700;
  color: #161E48;
}

h1, .h1, h2, .h2, h3 {
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: -0.01em;
}

h1, .h1 {
  font-size: 3em;
  line-height: 1.18;
  display: inline;
  position: relative;
  text-decoration: underline;
}

h2, .h2 {
  font-size: 1.5em;
}

h3, .h4, h4 {
  font-size: 1.5em;
  line-height: 1.45;
  text-decoration: underline;
}

h4, .h4 {
  font-size: 1.25em;
  letter-spacing: -0.01em;
  line-height: 1.25;
  text-transform: uppercase;
}

h5 {
  font-size: 1.1em;
}

h6 {
  font-size: 1em;
}

p {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.7;
}

a {
  transition: color 0.3s ease;
}
a:hover, a:focus {
  outline: none;
  text-decoration: none;
}

ul li, ol li {
  margin: 7.5px 0;
}
ul li:first-child, ol li:first-child {
  margin-top: 0;
}
ul li:last-child, ol li:last-child {
  margin-bottom: 0;
}

strong {
  font-weight: 700;
}

::selection {
  background: #FCEEA7;
  color: #161E48;
}

@media (max-width: 767.98px) {
  h1, .h1 {
    font-size: 2.5em;
  }
}
.btn {
  font-weight: 800;
  position: relative;
  font-size: 1.25em;
  letter-spacing: -0.01em;
  color: #161E48;
  text-transform: uppercase;
  padding: 15px 22px;
}
.btn:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  border: solid #FCEEA7 6px;
  transition: transform 0.3s cubic-bezier(0.4, 0.65, 0.17, 1.2);
}
.btn:hover:after, .btn:active:after {
  transform: scale(1.05);
}
.btn.black:after {
  border: solid #161E48 6px;
}

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
  padding-left: 40px;
  padding-right: 40px;
}

@media (max-width: 767.98px) {
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    padding-left: 15px;
    padding-right: 15px;
  }
}
header {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
}
header .logo {
  transition: transform 0.4s cubic-bezier(0.22, 0.46, 0.18, 1.13);
}
header .logo img {
  width: 172px;
  height: 43px;
}
header .logo:hover {
  transform: scale(1.03);
}
header .naviContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
header .raribleContainer {
  margin-left: 40px;
  transition: transform 0.4s cubic-bezier(0.22, 0.46, 0.18, 1.13);
}
header .raribleContainer a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
header .raribleContainer span {
  color: #FEDA03;
  text-transform: uppercase;
}
header .raribleContainer img {
  width: 73px;
  height: 73px;
  margin-left: 30px;
}
header .raribleContainer:hover {
  transform: scale(1.03);
}
header .navi {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
}
header .navi li {
  font-weight: 700;
  list-style: none;
  margin: 0 10px;
  text-transform: uppercase;
}
header .navi li a {
  position: relative;
  display: inline-block;
  color: #FCEEA7;
}
header .navi li a::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -10px;
  height: 4px;
  background-color: #FCEEA7;
  transform: scale(0, 1);
  transition: transform 0.3s ease;
  transform-origin: right center;
}
header .navi li:first-child {
  margin-left: 0;
}
header .navi li:last-child {
  margin-right: 0;
}
header .navi li:hover a::after, header .navi li.active a::after {
  transform-origin: left center;
  transform: scale(1, 1);
}

@media (max-width: 767.98px) {
  header {
    position: relative;
    flex-direction: column;
    background-color: #0C1025;
    padding: 25px;
  }
  header .naviContainer {
    justify-content: center;
    margin-top: 5px;
  }
  header .raribleContainer {
    display: none;
  }
  header .raribleContainer img {
    width: 35px;
    height: 35px;
    margin-left: 0;
  }
  header .raribleContainer span {
    display: none;
  }

  .logo {
    margin-bottom: 10px;
  }
}
#bigHeader {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 540px;
  height: 66vh;
  background-color: #162150;
}
#bigHeader .bigHeaderbg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-position: center center;
}
#bigHeader .bigHeaderbg.blured {
  filter: blur(30px);
  opacity: 0;
  transition: opacity 1.5s ease;
}
#bigHeader .bigheaderContent {
  padding: 120px 0 60px;
}
#bigHeader .bigheaderContent .headline {
  margin-bottom: 120px;
  padding-left: 90px;
}
#bigHeader .bigheaderContent h1 {
  color: #FFFFFF;
}
#bigHeader .bigheaderContent h1 .char,
#bigHeader .bigheaderContent h1 .whitespace {
  opacity: 0;
  transition: opacity 0.15s ease;
}
#bigHeader .bigheaderContent h1 .char.vis,
#bigHeader .bigheaderContent h1 .whitespace.vis {
  opacity: 1;
}
#bigHeader .bigheaderContent .countdownContainer {
  color: #FCEEA7;
  text-align: center;
}
#bigHeader .bigheaderContent .countdownContainer .countdown .ms {
  display: inline-block;
  min-width: 80px;
  text-align: right;
}
#bigHeader .bigheaderContent h2 {
  color: #FCEEA7;
  text-align: center;
}
#bigHeader .bigheaderContent h2 span {
  display: inline-block;
  margin: 0;
}
#bigHeader.loaded .bigHeaderbg.blured {
  opacity: 1;
}

@media (max-width: 767.98px) {
  #bigHeader {
    min-height: auto;
    height: auto;
  }
  #bigHeader .bigheaderContent {
    padding: 30px 0 60px;
  }
  #bigHeader .bigheaderContent h1 {
    font-size: 2.5em;
    margin-bottom: 15px;
    display: inline-block;
    color: #FFFFFF;
  }
  #bigHeader .bigheaderContent h2 {
    text-align: left;
  }
  #bigHeader .bigheaderContent .headline {
    padding-left: 15px;
    margin-bottom: 15px;
  }
  #bigHeader .bigheaderContent .countdownContainer {
    text-align: left;
  }
}
#about .teaserHeader {
  display: flex;
  justify-content: space-between;
  align-items: start;
}
#about .teaserHeader h3 {
  margin-bottom: 0;
}
#about .teaserHeader .btn {
  margin-top: 0;
}
#about .teaserContent {
  columns: 2;
  column-gap: 30px;
}

@media (max-width: 767.98px) {
  #about .teaserHeader {
    flex-direction: column;
  }
  #about .teaserHeader h3 {
    margin-bottom: 15px;
    width: 100%;
  }
  #about .teaserContent {
    columns: 1;
    column-gap: 0;
  }
}
#howItWorks {
  position: relative;
  z-index: 0;
}
#howItWorks .howItWorksCover {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-right: 70px;
}
#howItWorks .howItWorksCover svg {
  position: absolute;
  top: -45px;
  left: -38px;
  z-index: 1;
}
#howItWorks .howItWorksCover span {
  font-weight: 800;
  position: relative;
  z-index: 1;
  color: #FFFFFF;
  font-size: 6.25em;
  text-align: right;
  line-height: 0.4;
  margin-top: -14px;
  margin-right: -5px;
}
#howItWorks .howItWorksCover .imgContainer {
  position: relative;
  max-width: 360px;
}
#howItWorks .howItWorksCover .imgContainer .morph, #howItWorks .howItWorksCover .imgContainer .drawing, #howItWorks .howItWorksCover .imgContainer .faces {
  width: 100%;
}
#howItWorks .howItWorksCover .imgContainer .drawing {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
#howItWorks .howItWorksCover .imgContainer .faces {
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  max-width: 66.6666%;
}
#howItWorks .howItWorksContainer {
  padding-left: 32px;
}
#howItWorks .howItWorksContainer ul {
  list-style: none;
  counter-reset: item;
  padding: 0;
}
#howItWorks .howItWorksContainer ul li {
  font-weight: 400;
  position: relative;
  display: inline-block;
  counter-increment: item;
  margin-bottom: 30px;
  margin-top: 0;
  font-size: 1.25em;
  text-transform: uppercase;
  letter-spacing: -0.01em;
}
#howItWorks .howItWorksContainer ul li u:hover {
  text-decoration: line-through;
}
#howItWorks .howItWorksContainer ul li::before {
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -9px;
  left: -70px;
  content: counter(item);
  width: 43px;
  height: 43px;
  border: solid 2px white;
  border-radius: 50%;
  background-color: #FCEEA7;
  transition: background-color 0.3s ease;
}
#howItWorks .howItWorksContainer ul li:last-child {
  margin-bottom: 0;
}
#howItWorks .howItWorksContainer ul li:hover::before {
  background-color: #FFFFFF;
}
#howItWorks .howItWorksContainer .btn {
  display: inline-block;
}
#howItWorks::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(50% - 3px);
  background-color: #FFFFFF;
  width: 3px;
}
#howItWorks::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 60px;
  bottom: -240px;
  z-index: -1;
  background-color: #FCEEA7;
}

@media (max-width: 767.98px) {
  #howItWorks .howItWorksCover {
    padding-right: 0;
  }
  #howItWorks .howItWorksCover .imgContainer {
    max-width: 100%;
  }
  #howItWorks .howItWorksCover svg {
    top: -45px;
    left: 1px;
  }
  #howItWorks .howItWorksCover span {
    font-size: 3.8em;
  }
  #howItWorks .howItWorksContainer {
    padding-left: 0;
  }
  #howItWorks .howItWorksContainer ul {
    padding-left: 46px;
  }
  #howItWorks .howItWorksContainer ul li .countdown .ms {
    display: none;
  }
  #howItWorks .howItWorksContainer ul li::before {
    width: 35px;
    height: 35px;
    left: -48px;
    top: -6px;
  }
  #howItWorks::before {
    content: none;
  }
}
#creators h2 {
  text-align: center;
}
#creators img {
  width: 100%;
}
#creators p {
  text-decoration: none;
}

@media (max-width: 767.98px) {
  #creators img {
    min-width: 300px;
  }
  #creators h2 {
    text-align: left;
  }
}
#onRarible {
  background-color: #FEDA03;
  text-align: center;
}
#onRarible a {
  font-weight: 700;
  position: relative;
  display: inline-block;
  font-size: 1.25em;
  text-transform: uppercase;
  padding: 26px 36px 23px;
}
#onRarible a::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  border: solid 6px #161E48;
  transition: transform 0.3s cubic-bezier(0.29, 0.72, 0, 1.37);
}
#onRarible a:hover:after, #onRarible a:active:after {
  transform: scale(1.03);
}

@media (max-width: 767.98px) {
  #onRarible {
    padding: 20px;
  }
}
#footer {
  background-color: #0C1025;
  color: #FCEEA7;
}
#footer ul li, #footer p, #footer span {
  text-transform: uppercase;
  letter-spacing: -0.03em;
}
#footer a {
  color: #FCEEA7;
}
#footer a:hover {
  color: #FEDA03;
  text-decoration: line-through;
}
#footer #footerNavLeft ul, #footer #footerNavRight ul {
  padding: 0;
  margin: 0;
}
#footer #footerNavLeft ul li, #footer #footerNavRight ul li {
  list-style: none;
}
#footer #footerNavLeft ul li {
  font-weight: 700;
}
#footer .raribleContainer a {
  display: flex;
  justify-content: flex-end;
  color: #FEDA03;
  text-align: right;
}
#footer .raribleContainer img {
  width: 38px;
  height: 38px;
  margin-left: 20px;
}
#footer .schnurriContainer {
  position: relative;
  overflow: hidden;
}
#footer .schnurriContainer .hash {
  font-weight: 700;
  position: absolute;
  font-size: 1.3em;
  left: 100%;
  top: 0;
  bottom: 0;
  transition: transform 1.5s linear;
  z-index: 1;
  color: #0d1025;
  margin: 0;
}
#footer .schnurriContainer:hover .hash {
  transform: translate(calc(-100% - 55px), 0);
  transition: transform 2.5s linear;
}
#footer .schnurri {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 767.98px) {
  .raribleContainer {
    display: flex;
  }
  .raribleContainer span {
    text-align: left;
  }
  .raribleContainer span br {
    display: none;
  }
  .raribleContainer img {
    display: none;
  }

  #footer > .container > .row > .col-md-2 {
    display: none;
  }
  #footer .schnurri {
    justify-content: flex-start;
  }
}
#ipfsTeaser {
  background-color: #0C1025;
  color: #FFFFFF;
  overflow: hidden;
}
#ipfsTeaser .ipfsTeaserContent {
  position: relative;
  z-index: 0;
}
#ipfsTeaser .ipfsTeaserContent p {
  font-weight: 400;
  font-size: 1.5em;
  line-height: 1.25;
  margin: 0;
}
#ipfsTeaser .ipfsTeaserContent a {
  color: #FFFFFF;
  text-decoration: underline;
}
#ipfsTeaser .ipfsTeaserContent a:hover {
  text-decoration: line-through;
}
#ipfsTeaser .ipfsTeaserContent img {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  transform: translate(50%, 50%);
}

@media (max-width: 767.98px) {
  #ipfsTeaser .ipfsTeaserContent p {
    font-size: 1.2em;
  }
  #ipfsTeaser .ipfsTeaserContent p br {
    display: none;
  }
}
#jingles .teaserHeader .h3 {
  text-decoration: none;
}
#jingles .teaserHeader .h3 .marker {
  background-color: #0C1025;
  color: #FFFFFF;
  padding: 0 15px;
  margin: 0 7px;
}

#jingleContainer img, #jingleContainer audio {
  width: 100%;
}

.jingle {
  position: relative;
  z-index: 0;
  flex: 0 0 100%;
  max-width: 100%;
}
.jingle .h4 {
  font-weight: 700;
  hyphens: auto;
}
.jingle .jingleImg {
  position: relative;
  overflow: hidden;
  z-index: 0;
}
.jingle .jingleImg span {
  display: inline-block;
  position: absolute;
  right: 13px;
  bottom: 7px;
  margin: 0;
  text-decoration: none;
  z-index: 2;
}
.jingle .jingleImg::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 230px;
  height: 230px;
  transform: translate(50%, 50%);
  background: radial-gradient(circle, #FFFFFF 0%, rgba(255, 255, 255, 0) 60%);
  opacity: 0.6;
  z-index: 1;
  transition: opacity 0.2s ease;
  pointer-events: none;
}
.jingle .player {
  display: none;
}
.jingle .jinglePlayer {
  display: flex;
  margin-top: 15px;
}
.jingle .jinglePlayer .playBtn {
  position: relative;
  flex: 0 0 36px;
  cursor: pointer;
  border: solid 1px #0C1025;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin-right: 15px;
  opacity: 0.5;
  pointer-events: none;
}
.jingle .jinglePlayer .playBtn::after {
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  left: calc(50% + 1px);
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7.5px 0 7.5px 13px;
  border-color: transparent transparent transparent #0C1025;
}
.jingle .jinglePlayer .playBtn::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  border-radius: 50%;
  content: "";
  background-color: #FCEEA7;
  transition: all 0.45s cubic-bezier(0.22, 0.57, 0.28, 1.43);
  opacity: 0;
  transform: scale(0.7);
}
.jingle .jinglePlayer .playBtn .pause {
  display: none;
}
.jingle .jinglePlayer .playBtn .pause::after, .jingle .jinglePlayer .playBtn .pause::before {
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  left: calc(50% + 4px);
  content: "";
  width: 4px;
  height: 13px;
  background-color: #0C1025;
}
.jingle .jinglePlayer .playBtn .pause::after {
  left: calc(50% - 4px);
}
.jingle .jinglePlayer .playBtn:hover::before {
  opacity: 1;
  transform: scale(1.5);
}
.jingle .jinglePlayer .jingleContent .time {
  display: block;
  margin-top: 2px;
  font-size: 0.8em;
}
.jingle:hover .jingleImg::after {
  opacity: 0;
}
.jingle.playing .jinglePlayer .playBtn::before {
  opacity: 1;
  transform: scale(1);
}
.jingle.playing .jinglePlayer .playBtn::after {
  display: none;
}
.jingle.playing .jinglePlayer .playBtn .pause {
  display: block;
}
.jingle.playing .jinglePlayer .playBtn:hover::before {
  transform: scale(1.3);
}
.jingle.ready .jinglePlayer .playBtn {
  opacity: 1;
  pointer-events: all;
}

@media (max-width: 767.98px) {
  #jingleContainer {
    padding: 0 7px 0 8px;
  }
  #jingleContainer .col-xl, #jingleContainer .col-xl-auto, #jingleContainer .col-xl-12, #jingleContainer .col-xl-11, #jingleContainer .col-xl-10, #jingleContainer .col-xl-9, #jingleContainer .col-xl-8, #jingleContainer .col-xl-7, #jingleContainer .col-xl-6,
#jingleContainer .col-xl-5, #jingleContainer .col-xl-4, #jingleContainer .col-xl-3, #jingleContainer .col-xl-2, #jingleContainer .col-xl-1, #jingleContainer .col-lg, #jingleContainer .col-lg-auto, #jingleContainer .col-lg-12, #jingleContainer .col-lg-11,
#jingleContainer .col-lg-10, #jingleContainer .col-lg-9, #jingleContainer .col-lg-8, #jingleContainer .col-lg-7, #jingleContainer .col-lg-6, #jingleContainer .col-lg-5, #jingleContainer .col-lg-4, #jingleContainer .col-lg-3, #jingleContainer .col-lg-2,
#jingleContainer .col-lg-1, #jingleContainer .col-md, #jingleContainer .col-md-auto, #jingleContainer .col-md-12, #jingleContainer .col-md-11, #jingleContainer .col-md-10, #jingleContainer .col-md-9, #jingleContainer .col-md-8, #jingleContainer .col-md-7,
#jingleContainer .col-md-6, #jingleContainer .col-md-5, #jingleContainer .col-md-4, #jingleContainer .col-md-3, #jingleContainer .col-md-2, #jingleContainer .col-md-1, #jingleContainer .col-sm, #jingleContainer .col-sm-auto, #jingleContainer .col-sm-12,
#jingleContainer .col-sm-11, #jingleContainer .col-sm-10, #jingleContainer .col-sm-9, #jingleContainer .col-sm-8, #jingleContainer .col-sm-7, #jingleContainer .col-sm-6, #jingleContainer .col-sm-5, #jingleContainer .col-sm-4, #jingleContainer .col-sm-3,
#jingleContainer .col-sm-2, #jingleContainer .col-sm-1, #jingleContainer .col, #jingleContainer .col-auto, #jingleContainer .col-12, #jingleContainer .col-11, #jingleContainer .col-10, #jingleContainer .col-9, #jingleContainer .col-8, #jingleContainer .col-7, #jingleContainer .col-6,
#jingleContainer .col-5, #jingleContainer .col-4, #jingleContainer .col-3, #jingleContainer .col-2, #jingleContainer .col-1 {
    padding-right: 7px;
    padding-left: 8px;
  }

  #jingles .teaserHeader .h3 {
    font-size: 1.45rem;
  }
  #jingles .teaserHeader br {
    display: none;
  }

  .jingle .h4 {
    font-size: 0.85em;
    display: inline-block;
    margin: 0;
  }
  .jingle .jinglePlayer {
    margin-top: 10px;
  }
  .jingle .jinglePlayer .time {
    font-size: 0.7em;
    margin: 0;
  }
  .jingle .jinglePlayer .playBtn {
    flex: 0 0 30px;
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}
.isPage header {
  position: relative;
}
.isPage header .navi li a, .isPage header .raribleContainer span {
  color: #0C1025;
}

@media (max-width: 767.98px) {
  .isPage header {
    background-color: #FFFFFF;
  }
}
#whatIsNft {
  background-color: #FCEEA7;
  text-align: center;
}
#whatIsNft .h1:hover {
  text-decoration: line-through;
}