// Mixins
@mixin absolute {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

@mixin absoluteCenter {
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 50%;
    right: 0;
    transform: translate(-50%, -50%);
}

@mixin cover {
    background-size: cover;
    background-position: center center;
}


.sideFloat {
    width: calc((((100vw - #{$containerWidth})/ 2) + 100%) + 5px);
}

.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    margin: 20px 0;

    iframe, video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.embed-container {
    max-width: 500px;
    margin: 15px auto;

    iframe, twitter-widget {
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

.clear:after {
    content: '';
    display: table;
    clear: both;
}

// Inview Fade in
.fadeIn, .slideIn,
.fadeInRow > div,
.fadeInRow > li,
.fadeInRow > p,
.fadeInRow > span,
.fadeInRow > h3,
.fadeInRow > a {
    opacity: 0;
    transition: all 0s ease-out;
    transform: translate(0,10px);

    &.slideIn {
        transform: translate(-20px,0);
    }

    &.vis {
        transition: all .45s cubic-bezier(.49, .59, .21, 1.07) .15s;
        transform: translate(0, 0);
        opacity: 1;
    }
}

.mobile {
    display: none;

    @include media-breakpoint-down(sm) {
        display: block;
    }
}

.tablet {
    display: none;

    @include media-breakpoint-down(md) {
        display: block;
    }
}

.desktop {
    @include media-breakpoint-down(lg) {
        display: none;
    }
}
