#footer {
    background-color: $darkBlue;
    color: $yellow;

    ul li, p, span {
        text-transform: uppercase;
        letter-spacing: -.03em;
    }

    a {
        color: $yellow;

        &:hover {
            color: $darkYellow;
            text-decoration: line-through;
        }
    }

    #footerNavLeft, #footerNavRight {
        ul {
            padding: 0;
            margin: 0;

            li {
                list-style: none;
            }
        }
    }

    #footerNavLeft {
        ul li {
            @include bold;
        }
    }

    .raribleContainer {

        a {
            display: flex;
            justify-content: flex-end;
            color: $darkYellow;
            text-align: right;
        }

        img {
            width: 38px;
            height: 38px;
            margin-left: 20px;
        }
    }

    .schnurriContainer {
        position: relative;
        overflow: hidden;

        .hash {
            @include bold;
            position: absolute;
            font-size: 1.3em;
            left: 100%;
            top: 0;
            bottom: 0;
            transition: transform 1.5s linear;
            z-index: 1;
            color: #0d1025;
            margin: 0;
        }

        &:hover {
            .hash {
                transform: translate(calc(-100% - 55px), 0);
                transition: transform 2.5s linear;
            }
        }
    }

    .schnurri {
        display: flex;
        justify-content: flex-end;
    }
}

@include media-breakpoint-down(sm) {
    .raribleContainer {
        display: flex;

        span {
            text-align: left;

            br {
                display: none;
            }
        }

        img {
            display: none;
        }
    }

    #footer {
        & > .container > .row > .col-md-2 {
            display: none;
        }

        .schnurri {
            justify-content: flex-start;
        }
    }
}
