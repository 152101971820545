#creators {
    h2 {
        text-align: center;
    }

    img {
        width: 100%;
    }

    p {
        text-decoration: none;
    }
}

@include media-breakpoint-down(sm) {
    #creators {
        img {
            min-width: 300px;
        }

        h2 {
            text-align: left;
        }
    }
}
