// font-face include
@import url('https://use.typekit.net/kck8fmm.css');

// font weight mixins
@mixin regular {
    font-weight: 400;
}

@mixin bold {
    font-weight: 700;
}

@mixin extraBold {
    font-weight: 800;
}

@mixin black {
    font-weight: 900;
}

@mixin headline {
    @include bold;
}

// font styling
body {
    font-size: $bodyFontSize;
}

h1, h2, h3, h4, h5, h6, p, a, span, ul, ol {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: $line-height-base;
    margin-top: $grid-gutter-width / 2;
    margin-bottom: $grid-gutter-width / 2;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}


h1, .h1, h2, .h2, h3, h4 {
    @include headline;
    color: $black;
}

h1, .h1, h2, .h2, h3 {
    @include regular;
    text-transform: uppercase;
    letter-spacing: -.01em;
}

h1, .h1 {
    font-size: 3em;
    line-height: 1.18;
    display: inline;
    position: relative;
    text-decoration: underline;
    //     background-image: linear-gradient(to bottom,$darkBlue 0, $darkBlue 100%);
    //     background-position: 0 120%;
    //     background-repeat: no-repeat;
    //     background-size: 100% 13px;
}

h2, .h2 {
    font-size: 1.5em;
}

h3, .h4, h4 {
    font-size: 1.5em;
    line-height: 1.45;
    text-decoration: underline;
}

h4, .h4 {
    font-size: 1.25em;
    letter-spacing: -.01em;
    line-height: 1.25;
    text-transform: uppercase;
}

h5 {
    font-size: 1.1em;
}

h6 {
    font-size: 1em;
}

p {
    font-size: 1em;

    @include regular;
    line-height: 1.7;
}

a {
    transition: color .3s ease;

    &:hover, &:focus {
        outline: none;
        text-decoration: none;
    }
}

ul, ol {
    li {
        margin: $grid-gutter-width/4 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

strong {
    @include bold;
}

// Text/Content Selection
::selection {
    background: $selectionBg;
    color: $selectionColor;
}

@include media-breakpoint-down(sm) {
    h1, .h1 {
        font-size: 2.5em;
    }
}
