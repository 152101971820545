.isPage {
    header {
        position: relative;

        .navi li a, .raribleContainer span {
            color: $darkBlue;
        }
    }
}

@include media-breakpoint-down(sm) {
    .isPage {
        header {
            background-color: $white;
        }
    }
}
