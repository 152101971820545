#ipfsTeaser {
    background-color: $darkBlue;
    color: $white;
    overflow: hidden;

    .ipfsTeaserContent {
        position: relative;
        z-index: 0;

        p {
            @include regular;
            font-size: 1.5em;
            line-height: 1.25;
            margin: 0;
        }

        a {
            color: $white;
            text-decoration: underline;

            &:hover {
                text-decoration: line-through;
            }
        }

        img {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: -1;
            transform: translate(50%, 50%);
        }
    }
}


@include media-breakpoint-down(sm) {
    #ipfsTeaser .ipfsTeaserContent p {
        font-size: 1.2em;

        br {
            display: none;
        }
    }
}
