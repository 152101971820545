#whatIsNft {
    background-color: $yellow;
    text-align: center;

    .h1 {
        &:hover {
            text-decoration: line-through;
        }
    }
}
