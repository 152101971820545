.btn {
    @include extraBold;
    position: relative;
    font-size: 1.25em;
    letter-spacing: -.01em;
    color: $black;
    text-transform: uppercase;
    padding: 15px 22px;

    &:after {
        @include absolute;
        content: '';
        border: solid $yellow 6px;
        transition: transform .3s cubic-bezier(.4, .65, .17, 1.2);
    }

    &:hover, &:active {
        &:after {
            transform: scale(1.05);
        }
    }

    &.black {
        &:after {
            border: solid $black 6px;
        }
    }
}
