.container-fluid {
    padding-left: 40px;
    padding-right: 40px;
}

@include media-breakpoint-down(sm) {
    .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }
}
